<template>
  <div role="main" class="main">
    <header class="header">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-12">
            <nav class="navbar navbar-expand-md navbar-dark pl-lg-0">
              <a class="navbar-brand" href="javascript:void(0);">{{
                event ? event.decease_name : "MOURN WITH US"
              }}</a>
              <div class="navbar-list">
                <router-link
                  tag="a"
                  class="text-yellow hover-yellow mr-3 mr-md-5"
                  to="/speaker/profile"
                  >Profile</router-link
                >
              </div>
              <div class="action logout">
                <a
                  href="#"
                  class="text-yellow hover-yellow mr-3"
                  @click="logout"
                  ><i class="fas fa-sign-out-alt"></i>Logout</a
                >
                <button class="btn btn-danger" @click="disconnect()">
                  End
                </button>
              </div>
            </nav>
          </div>
        </div>
      </div>
    </header>
    <section class="together_we_join minh-custom with-footer speaker-room-page">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <p class="text-uppercase">
              Together we join you. We are here with you
            </p>
            <ul class="list-unstyled">
              <li
                v-for="visitor in visitors"
                :key="visitor.visitor_id"
                class="mr-3"
              >
                <a href="#">
                  <img
                    :src="visitor.profile_url"
                    class="img-fluid br-50 fixed-image"
                  />
                </a>
                <br />
                <small> {{ visitor.full_name }}</small>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
    <footer class="container">
      <div class="row align-items-center">
        <div class="col-md-7">
          <div class="participate speaker_list">
            <ul class="list-unstyled c-speaker mb-0">
              <li
                v-for="speaker in speakers"
                :key="speaker.speaker_id"
                style="margin-right: 0px"
              >
                <!-- <div
                  id="publisher"
                  v-if="
                    speaker.content_type_id == content_type_id &&
                    speaker.speaker_type == 1
                  "
                  class="img-fluid call"
                  style="display: none"
                ></div>
                <div
                  id="subscriber"
                  v-if="
                    speaker.content_type_id != content_type_id &&
                    speaker.speaker_type == 1
                  "
                  class="call-subscriber"
                ></div> -->

                <div style="margin-right: 15px">
                  <img
                    v-if="
                      speaker.content_type_id != content_type_id &&
                      speaker.speaker_type == 0
                    "
                    :src="speaker.profile_url"
                    class="img-fluid br-50"
                  />
                </div>
                <span
                  v-if="
                    speaker.content_type_id != content_type_id &&
                    speaker.speaker_type == 0
                  "
                >
                  VIDEO
                </span>
                <div style="margin-right: 15px">
                  <img
                    v-if="
                      speaker.content_type_id == content_type_id &&
                      speaker.speaker_type == 0
                    "
                    :src="speaker.profile_url"
                    class="img-fluid br-50"
                  />
                </div>
                <span
                  v-if="
                    speaker.content_type_id == content_type_id &&
                    speaker.speaker_type == 0
                  "
                >
                  VIDEO
                </span>

                <!-- <i>
                  <img :src="require('@/assets/img/i-icon.png')" />
                </i> -->
                <img
                  :src="speaker.profile_url"
                  v-if="speaker.speaker_type != 0"
                  class="img-fluid br-50"
                />

                <span v-if="speaker.id == live_id"> Live </span>
                <div
                  class="mt-2 d-table"
                  v-if="speaker.content_type_id == first_content_id"
                >
                  <button
                    type="button"
                    class="btn btn-warning btn-sm"
                    style="width: 6rem; margin-right: 15px"
                    @click="countDown(speaker)"
                    v-if="
                      (speaker.content_type_id != content_type_id &&
                        speaker.speaker_type != 1) ||
                      (speaker.content_type_id == content_type_id &&
                        speaker.speaker_type == 1)
                    "
                  >
                    Start
                  </button>
                  <button
                    type="button"
                    class="btn btn-warning btn-sm"
                    v-if="
                      speaker.content_type_id != content_type_id &&
                      speaker.speaker_type == 1
                    "
                    @click="countDown(speaker)"
                  >
                    Pass the mic
                  </button>
                </div>
                <!-- <button
                  type="button"
                  class="btn btn-warning btn-sm"
                  v-if="
                    speaker.content_type_id == content_type_id &&
                    unpublish == true
                  "
                  style="width: 6rem"
                  @click="countDown"
                >
                  Start
                </button>

                <button
                  type="button"
                  class="btn btn-warning btn-sm"
                  v-if="
                    speaker.content_type_id != content_type_id &&
                    unpublish == true
                  "
                  @click="countDown"
                >
                  Pass the mic
                </button> -->
                <!-- </div> -->
              </li>
            </ul>
          </div>
        </div>
        <div class="col-md-5 text-center text-lg-right" v-if="event">
          <img
            :src="event.program_url"
            class="img-fluid pointer"
            style="max-height: 110px"
            @click="enlarge()"
          />
        </div>
      </div>
    </footer>

    <!-- Eulogy Notes -->
    <transition name="pop" appear>
      <div
        class="custom-modal note-modal"
        v-show="show_note_modal"
        id="EulogyNotes"
        tabindex="-1"
        role="dialog"
        aria-labelledby="Eulogy Notes"
        aria-hidden="true"
        v-draggable="draggableValue"
      >
        <div
          class="modal-dialog modal-dialog-centered modal-sm"
          role="document"
        >
          <div class="modal-content">
            <div class="modal-body">
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
                @click="closeNoteModal"
              >
                <span aria-hidden="true" class="text-white">&times;</span>
              </button>
              <div class="row">
                <div class="col-lg-12">
                  <h5 class="text-custom text-left">
                    <img
                      :src="require('@/assets/img/move_icon.png')"
                      :ref="handleId"
                    />Notes
                  </h5>
                </div>
                <div
                  class="col-lg-12 text-custom mt-4 scroll-notes"
                  style="max-height: 200px"
                >
                  {{ notes }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>

    <!-- Countdown -->
    <transition name="pop" appear>
      <div
        class="modal fade show custom-modal"
        v-if="show_count_modal"
        id="Countdown"
        tabindex="-1"
        role="dialog"
        aria-labelledby="Countdown"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-body">
              <div class="row">
                <div class="col-lg-4 text-center">
                  <div class="participate">
                    <ul class="list-unstyled mb-0" v-if="my_info">
                      <li>
                        <i>
                          <img :src="require('@/assets/img/i-icon.png')" />
                        </i>
                        <img
                          :src="my_info.profile_url"
                          class="img-fluid br-50"
                        />
                        <p class="text-custom">
                          {{ my_info.name }}<br />
                          <small>Eulogy Reading</small>
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="col-lg-8 text-center">
                  <h5 class="text-custom my-4">You will be LIVE in</h5>
                  <div class="countdown">
                    {{ count_number }}
                  </div>
                  <p class="text-white mt-5">
                    {{ visitors.length }} People attending are watching you now
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>

    <!-- Modal -->
    <transition name="pop" appear>
      <div
        class="modal fade show program-modal"
        v-if="show_modal"
        id="myModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="myModal"
        aria-hidden="true"
        @click="closeModal"
      >
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-body">
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
                @click="closeModal"
              >
                <span aria-hidden="true">&times;</span>
              </button>
              <div class="row" v-if="event">
                <div class="col-lg-12">
                  <img :src="event.program_url" class="img-fluid" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>
<script>
import { getApiManager } from "../../api";
import { apiBaseUrl, liveBaseUrl } from "../../constants/config";
import { mapActions } from "vuex";
import { Draggable } from "draggable-vue-directive";

import adapter from "webrtc-adapter";
window["adapter"] = adapter;

export default {
  directives: {
    Draggable,
  },
  data() {
    return {
      handleId: "handle-id",
      draggableValue: {
        handle: undefined,
      },
      speakers: [],
      visitors: [],
      my_info: null,
      event_id: 0,
      live_id: 0,
      first_content_id: 1,
      show_note_modal: false,
      show_count_modal: false,
      count_number: 20,
      checkTimer: null,
      content_type_id: 0,
      event: null,
      show_modal: false,
      connection: null,
      notes: "",
      apikey: "",
      sessionId: "",
      subscribers: [],
      token: "",
      unpublish: false,
    };
  },
  mounted() {
    this.draggableValue.handle = this.$refs[this.handleId];
    this.content_type_id = JSON.parse(localStorage.getItem("loginInfo"))
      ? JSON.parse(localStorage.getItem("loginInfo")).id
      : 0;
    this.event_id = JSON.parse(localStorage.getItem("loginInfo"))
      ? JSON.parse(localStorage.getItem("loginInfo")).event_id
      : 0;
    this.Live_id = localStorage.getItem("Live_id");
    getApiManager()
      .post(`${apiBaseUrl}/api/speaker/profile/modifylive`, {
        live: 1,
        content_type_id: this.content_type_id,
      })
      .then((response) => {});
    this.getDetail();
    // this.checkTimer = setInterval(() => {
    //   this.checkLive()
    // }, 1000 * 10);

    this.connectSocket();
  },
  methods: {
    publishCall() {
      getApiManager()
        .post(`${apiBaseUrl}/api/vonage/getToken`, {
          decease_name: this.event.decease_name,
        })
        .then((result) => {
          this.sessionId = result.data.data[0].sessionId;
          this.apikey = result.data.apiKey;
          this.token = result.data.data[0].token;
          if (self.OTSession) {
            self.OTSession.disconnect();
          }
          self.OTSession = self.OT.initSession(
            result.data.apiKey,
            result.data.data[0].sessionId
          );

          self.OTSubscriberArray = [];
          self.OTEVENTStream = "";
          self.OTSession.on("streamCreated", function streamCreated(event) {
            self.OTSubscriberArray = self.OTSession.getSubscribersForStream(
              event.stream
            );
            if (self.OTSubscriberArray.length > 0) {
              for (let i = 0; i < self.OTSubscriberArray.length; i++) {
                self.OTSession.unsubscribe(self.OTSubscriberArray[i]);
              }
            }
            var subscriberOptions = {
              insertMode: "before",
              width: "100%",
              height: "100%",
            };
            self.OTSubscriber = self.OTSession.subscribe(
              event.stream,
              "subscriber",
              subscriberOptions,
              handleError
            );
            self.OTSubscriberArray = self.OTSession.getPublisherForStream(
              event.stream
            );

            self.OTEVENTStream = event.stream;
          });

          self.OTSession.on("streamDestroyed", function streamCreated(event) {
            console.log("CALLLspeakerroom", event);
          });

          var publisherOptions = {
            insertMode: "before",
            resolution: "1280x720",
            width: "100%",
            height: "100%",
          };
          self.OTpublisher = self.OT.initPublisher(
            "publisher",
            publisherOptions,
            handleError
          );

          // Connect to the session
          self.OTSession.connect(
            result.data.data[0].token,
            function callback(error) {
              if (error) {
                handleError(error);
              } else {
                // If the connection is successful, publish the publisher to the session
                self.OTSession.publish(self.OTpublisher, handleError);
              }
            }
          );
          function handleError(error) {
            if (error) {
              console.error(error);
            }
          }
        });
      // this.joinCall();
    },
    start() {
      clearInterval(this.checkTimer);
      this.count_number = 20;
      this.show_count_modal = false;
      this.connection.close();

      if (self.OTpublisher) {
        self.OTpublisher.publishVideo(false);
        // self.OTSession.disconnect();
      }

      //Off but current
      if (self.OTSession) {
        self.OTSession.disconnect();
      }
      // for (var j = 0; j < self.OTSubscriberArray.length; j++) {
      //   console.log(self.OTSubscriberArray[j]);
      //   self.OTSession.unpublish(self.OTSubscriberArray[j]);
      // }
      self.OTSession = self.OT.initSession(this.apikey, this.sessionId);
      var publisherOptions = {
        insertMode: "before",
        resolution: "1280x720",
        width: "100%",
        height: "100%",
      };
      self.OTpublisher = self.OT.initPublisher(
        "publisher",
        publisherOptions,
        handleError
      );
      function handleError(error) {
        if (error) {
          console.error(error);
        }
      }

      self.OTSession.connect(this.token, function callback(error) {
        if (error) {
          handleError(error);
        } else {
          // If the connection is successful, publish the publisher to the session
          self.OTSession.publish(self.OTpublisher, handleError);
        }
      });

      getApiManager()
        .post(`${apiBaseUrl}/api/vonage/broadcast`, {
          sessionId: this.sessionId,
        })
        .then((result) => {
          let randomnumber = Math.floor(Math.random() * 10000) + 1;
          this.$router.push("/speaker/live/"+this.event_id+"?x=" + randomnumber);
        });
    },
    disconnect() {
      getApiManager()
        .post(`${apiBaseUrl}/api/speaker/event/modify`, { status: 3 })
        .then((response) => {
          let res = response.data;
          if (res.status === true) {
            this.connection.send(
              JSON.stringify({
                type: "event_distroy",
              })
            );
          }
        });
    },
    disconnect_old() {
      if (self.OTSession) {
        self.OTSession.disconnect();
        if (
          localStorage.getItem("Live_id") &&
          localStorage.getItem("Live_id") != null &&
          localStorage.getItem("Live_id") != "null"
        ) {
          localStorage.setItem("Live_id", null);
          localStorage.setItem("deceased_name",'')
          this.connection.send(
            JSON.stringify({
              type: "event_distroy",
              order: 1,
            })
          );
          getApiManager()
            .post(`${apiBaseUrl}/api/speaker/event/modify`, { status: 3 })
            .then((response) => {
              let res = response.data;
              if (res.status === true) {
                let randomnumber = Math.floor(Math.random() * 10000) + 1;
                this.$router.push("/speaker/profile?x=" + randomnumber);
              }
            });
        } else {
          this.unpublish == false;
          let randomnumber = Math.floor(Math.random() * 10000) + 1;
          this.$router.push("/speaker/profile?x=" + randomnumber);
        }
      } else {
        console.log("333333333333333333333333333!");
        localStorage.setItem("Live_id", null);
        localStorage.setItem("deceased_name",'')
        let randomnumber = Math.floor(Math.random() * 10000) + 1;
        this.$router.push("/speaker/profile?x=" + randomnumber);
      }
    },
    ...mapActions(["signOut"]),
    connectSocket() {
      var ref = this;

      this.connection = new WebSocket(liveBaseUrl);
      this.connection.onmessage = function (event) {
        console.log("@@@@@@@@@@@@@@@@@", event);
        //this.isLoading = false;
        let data = JSON.parse(event.data);
        if (data.type == "change_order") {
          ref.receiveMoveLive(data.order);
        } else if (data.type == "user_info") {
          ref.connection.send(
            JSON.stringify({
              type: "my_info",
              event_id: ref.event_id,
              content_type_id: ref.content_type_id,
            })
          );
        } else if (data.type == "event_distroy") {
          // localStorage.setItem("Live_id", null);
          let randomnumber = Math.floor(Math.random() * 10000) + 1;
          ref.$router.push("/speaker/profile?x=" + randomnumber);
          // window.location.reload();
        }
      };

      this.connection.onclose = (e) => {
        console.log(
          "Socket is closed. Reconnect will be attempted in 1 second."
        );
        setTimeout(() => {
          ref.connectSocket();
        }, 1000);
      };
    },
    receiveMoveLive(order, speaker) {
      console.log("receiveMoveLive");

      if (self.OTSession) {
        // self.OTpublisher.publishVideo(false);
        self.OTSession.disconnect();
      }

      this.connection.close();
      let randomnumber = Math.floor(Math.random() * 10000) + 1;
      // this.$router.push("/speaker/live?x=" + randomnumber);
    },
    showNoteModal() {
      this.show_note_modal = true;
    },
    closeNoteModal() {
      this.show_note_modal = false;
    },
    logout() {
      if (self.OTSession) {
        self.OTSession.disconnect();
      }
      getApiManager()
        .post(`${apiBaseUrl}/api/speaker/profile/modifylive`, {
          live: 0,
          content_type_id: this.content_type_id,
        })
        .then((response) => {
          localStorage.setItem("Live_id", null);
          localStorage.setItem("deceased_name",'')
          this.signOut();
          this.$router.push("/login");
          window.location.reload();
        });
    },
    closeModal() {
      this.show_modal = false;
    },
    enlarge() {
      this.show_modal = true;
    },
    countDown(current_Speaker) {
      var ref = this;
      getApiManager()
        .post(`${apiBaseUrl}/api/speaker/event/modify`, {
          status: 2,
          cur_order: current_Speaker.order,
          cur_speaker: current_Speaker
            ? current_Speaker.content_type_id
            : this.content_type_id,
        })
        .then((response) => {
          let res = response.data;
          if (res.status === true) {
            clearInterval(this.checkTimer);
            this.show_count_modal = true;
            ref.connection.send(
              JSON.stringify({
                type: "change_order",
                order: 2,
                content_type_id: current_Speaker
                  ? current_Speaker.content_type_id
                  : ref.content_type_id,
              })
            );
            // this.showNoteModal();
            // this.countDownTimer();
            // self.OTSession.disconnect();
            ref.start();
          }
        })
        .catch((error) => {
          this.$notify({
            group: "foo",
            type: "warn",
            title: "Error",
            text: "Server Error",
            animation_type: "slide",
          });
        });
    },
    countDownTimer() {
      if (this.count_number > 0) {
        setTimeout(() => {
          this.count_number -= 1;
          this.countDownTimer();
        }, 1000);
      } else {
        this.start();
        // self.OTSession.disconnect();
      }
    },
    getDetail() {
      var ref = this;
      getApiManager()
        .post(`${apiBaseUrl}/api/speaker/alllive`)
        .then((response) => {
          let res = response.data;
          if (res.status === true) {
            let detail = res.detail;
            ref.speakers = detail.speakers;
            this.speakers = detail.speakers;
            for (var i = 0; i < ref.speakers.length; i++) {
              ref.speakers[i].profile_url =
                apiBaseUrl + "/" + ref.speakers[i].profile_url;
              if (ref.speakers[i].content_type_id == ref.content_type_id) {
                ref.my_info = ref.speakers[i];
                ref.notes = ref.speakers[i].notes;
              }
              if (
                this.content_type_id == ref.speakers[i].content_type_id &&
                ref.speakers[i].speaker_type == 1
              ) {
                this.unpublish = true;
              }
            }
            ref.first_content_id = ref.speakers[0].content_type_id;
            ref.visitors = detail.visitors;
            for (i = 0; i < ref.visitors.length; i++) {
              ref.visitors[i].profile_url =
                apiBaseUrl + "/" + ref.visitors[i].profile_url;
            }
            ref.event = detail.event;
            ref.event.program_url = apiBaseUrl + "/" + ref.event.program_url;
            if (this.unpublish) {
              //hide opentok calling feature
              // this.publishCall();
            }
          }
        })
        .catch((error) => {});
    },
    checkLive() {
      getApiManager()
        .post(`${apiBaseUrl}/api/speaker/event/get`)
        .then((response) => {
          let res = response.data;
          if (res.status === true) {
            let detail = res.detail;
            if (detail.status == 2) {
              clearInterval(this.checkTimer);
              let randomnumber = Math.floor(Math.random() * 10000) + 1;
              this.$router.push("/speaker/live/"+this.event_id+"?x=" + randomnumber);
            }
          }
        })
        .catch((error) => {});
    },
    handlerClose(e, ev, evv) {
      console.log(e);
      console.log(ev);
      console.log(evv);

      this.removefromlive(function () {});
      // e.returnValue = "Are you sure want to leave?";
      console.log("Ddddddddddd");
    },
  },
  created() {
    window.onbeforeunload = this.handlerClose;
    // window.onunload = this.handlerClose;
    // window.addEventListener("focus", this.handlerunload);
    // console.log(window);
  },
  components: {},
  computed: {},
  watch: {},
  beforeDestroy() {
    //  getApiManager().post(`${apiBaseUrl}/api/speaker/event/modify`, { status: 0 })
    //             .then((response) => {
    //     });
  },
  destroyed() {
    // if (
    //   localStorage.getItem("Live_id") &&
    //   localStorage.getItem("Live_id") != null &&
    //   localStorage.getItem("Live_id") != "null"
    // ) {
    //   this.connection.send(
    //     JSON.stringify({
    //       type: "event_distroy",
    //       order: 1,
    //     })
    //   );
    // }
    if (self.OTSession) {
      self.OTSession.disconnect();
    }
  },
};
</script>

<style scoped>
.call {
  overflow: auto;
  border-radius: 50%;
  border: solid #a99711db;
}

.participate .list-unstyled {
  display: flex !important;
}
participate .text-label {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  left: -8px !important;
  margin-right: 15px;
  top: 0 !important;
  background-color: #00000000 !important;
  position: absolute !important;
}
.participate .text-label span {
  background-color: #000 !important;
  left: unset !important;
  top: unset !important;
}
#subscriber {
  display: flex !important;
}
</style>